import * as React from "react"
import { Link } from "gatsby"

import classNames from "@molnx-sites/common/utils/classNames"
import { Spacer, HBox, BigButton, Logo } from "@molnx-sites/common/components"

import { Page, PageSection, SEO } from "src/components"

import styles from "./index.module.scss"

export default function IndexPage() {
    return (
        <Page className={styles.Page}>
            <SEO title="Home"/>

            <PageSection dark className={styles.PageHeader}>
                <Logo big light/>
                <Spacer height="large"/>

                <p>
                    Hosted services and API:s that simplify the development of modern, cloud-based
                    applications.
                </p>

                <Spacer height="medium"/>

                <HBox wrap className={styles.ButtonRow}>
                    <BigButton href="https://portal.molnx.com">Go to portal</BigButton>
                    <BigButton href="https://portal.molnx.com">Get SDK</BigButton>
                </HBox>
            </PageSection>

            <PageSection className={styles.HighlightList}>
                <ul>
                    <li>Simple API:s and SDK:s</li>
                    <li>Permanent free tiers</li>
                    <li>Serverless pay-as-you go pricing</li>
                </ul>
            </PageSection>

            <PageSection className={classNames(styles.DispatchIntro)}>
                <Link to="/dispatch" className={styles.SectionLink}>
                    <h1>Dispatch</h1>

                    <p>
                        Easily schedule application messages to be delivered at a specific time in the future.
                        Deliver messages to your custom <em>Webhooks</em>, <em>Amazon SQS</em> and <em>SNS</em>.
                    </p>

                    <p><em>Learn more »</em></p>
                </Link>
            </PageSection>

            <PageSection>
                <p>
                    Bacon ipsum dolor amet pork belly ham hock ribeye, swine meatloaf picanha pork prosciutto. Tri-tip
                    turducken chicken porchetta drumstick leberkas brisket kevin shankle. Burgdoggen chuck tenderloin,
                    pork
                    belly buffalo andouille fatback filet mignon drumstick salami ribeye tongue cupim.
                </p>
                <p>
                    T-bone corned beef tri-tip shank frankfurter hamburger brisket bacon pig chislic doner cow. Kielbasa
                    pork loin strip steak capicola. Pork belly meatball pancetta, picanha prosciutto pork loin porchetta
                    turkey. Ham hock pancetta kielbasa drumstick, pork pig doner shoulder capicola tri-tip.
                </p>
                <p>
                    Short ribs short loin corned beef, sausage fatback tenderloin doner kevin turducken prosciutto filet
                    mignon pancetta ground round. Pastrami beef ribs jerky alcatra burgdoggen cupim. Alcatra pork belly
                    ribeye kevin sirloin rump. Shankle sausage beef chislic turducken burgdoggen porchetta ribeye cow
                    pork
                    chop frankfurter tongue pork belly.
                </p>
            </PageSection>
        </Page>
    )
}
